import {
    FC,
    PropsWithChildren,
    forwardRef,
    RefAttributes,
    useRef,
    useImperativeHandle,
    useEffect,
    useState,
} from "react";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

type Props = {
    settings: Settings;
    dynamicSlidesToShow?: boolean;
};

const SlickSlider: FC<Props & PropsWithChildren & RefAttributes<Slider>> = forwardRef(
    ({ children, settings, dynamicSlidesToShow }, ref) => {
        const localRef = useRef<Slider>(null);
        const [slidesToShow, setSlidesToShow] = useState(settings.slidesToShow);

        useImperativeHandle(ref, () => localRef.current!);

        useEffect(() => {
            if (dynamicSlidesToShow && localRef.current && localRef.current.innerSlider) {
                const slickList = localRef.current.innerSlider.list;
                if (slickList) {
                    const slickTrack = slickList.children.item(0);
                    if (slickTrack) {
                        setSlidesToShow(
                            Math.floor(slickList?.clientWidth / slickTrack.children[0].clientWidth),
                        );
                    }
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        return (
            <Slider {...{ ...settings, slidesToShow }} ref={localRef}>
                {children}
            </Slider>
        );
    },
);

export default SlickSlider;
