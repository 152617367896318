import { CasinoGameTag, Language } from "@finbackoffice/enums";
import {
    formatAppNameText,
    CasinoGameTypeEnum,
    CasinoLayoutEnum,
    IRuntimeConfig,
} from "@finbackoffice/fe-core";
import { BrowserStorageIdsEnum, CasinoScopesMap } from "@finbackoffice/site-core";
import { DEFAULT_ROOT_LOCALE } from "@finbackoffice/site-server-core";

export type CasinoGameImgFolderType = "narrow" | `central/${string}`;

type GetCasinoGameImgSrcOptions = {
    showVideo?: boolean;
    imgVariant?: string;
    useAppName?: boolean;
    folder?: CasinoGameImgFolderType;
};

export const getCasinoGameImgSrc = (
    runtimeConfig: IRuntimeConfig,
    gameId: string,
    gameType: CasinoGameTypeEnum,
    tags: CasinoGameTag[],
    options?: GetCasinoGameImgSrcOptions,
) => {
    let gameImgId = gameId.replace("-mobile", "");
    let dir = `${runtimeConfig.ASSETS_URL}/common/casino`;
    let extention;

    if (
        [CasinoLayoutEnum.Compact, CasinoLayoutEnum.GameStore].includes(
            runtimeConfig.SITE_CONFIG.layouts._casino.type,
        )
    ) {
        extention = tags.includes(CasinoGameTag.VFX) ? "mp4" : "webp";
        dir += "/compact";

        if (CasinoScopesMap.slots.includes(gameType as CasinoGameTypeEnum)) {
            dir += "/slot-games";
        } else {
            dir += `/live-casino-games`;
        }

        if (options?.folder) {
            dir += `/${options.folder}`;
        }

        if (options?.showVideo) {
            if (options.imgVariant) {
                gameImgId += `_${options.imgVariant}`;
            }
            extention = "mp4";
        }

        if (tags.includes(CasinoGameTag.CGFX)) {
            gameImgId = `cgfx_${gameImgId}`;
        }
    }

    return `${dir}/${gameImgId}${
        options?.useAppName
            ? `_${formatAppNameText(runtimeConfig.COMMON_SITE_CONFIGS.appName)}`
            : ""
    }.${extention}`;
};

export const getCurrentLocale = (
    runtimeConfig: IRuntimeConfig,
    currentLocale: string = runtimeConfig.COMMON_SITE_CONFIGS.defaultLocale,
) => {
    if (currentLocale === DEFAULT_ROOT_LOCALE) {
        return runtimeConfig.COMMON_SITE_CONFIGS.defaultLocale;
    }
    return currentLocale as Language;
};

export function generateUUID() {
    if (typeof window === "undefined") {
        // server
        return "";
    }

    // browser
    const localStorageValue = localStorage.getItem(BrowserStorageIdsEnum.DEVICE_ID);
    if (localStorageValue) {
        return localStorageValue;
    }
    const uuid = crypto.randomUUID();
    localStorage.setItem(BrowserStorageIdsEnum.DEVICE_ID, uuid);
    return uuid;
}
